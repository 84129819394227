<template>
  <!--begin::Brand-->
  <div
    class="aside-brand d-flex flex-column align-items-center flex-column-auto py-5 pt-lg-8"
  >
    <!--begin::Logo-->
    <router-link to="/" class="text-center">
      <!-- <img :src="siteLogo()" alt="Logo" class="max-h-30px" /> -->
      <img :src="setting.city_logo" alt="Logo" class="max-h-55px" />
    </router-link>
    <!--end::Logo-->
  </div>
  <!--end::Brand-->
</template>

<style lang="scss" scoped>
.aside-toggle {
  outline: none;
}
</style>

<style scoped>
.btn.btn-clean:hover span i {
  color: #00b0ff !important;
}
</style>

<script>
import { mapGetters } from "vuex";
import objectPath from "object-path";
import { getConfiguration } from "@/core/services/jwt.service.js";
import module from "@/core/modules/CrudModule.js";

export default {
  name: "KTBrand",
  mounted() {
    this.getSetting();
  },
  data() {
    return {
      configuration: getConfiguration() != null ? "" : "",
      setting: {},
    };
  },
  methods: {
    async getSetting() {
      let response = await module.get("settings-no-auth/1");
      if (response != null) {
        this.setting = response;
        this.background_image = response.cover_login;
        this.$refs.cover.style.backgroundImage = `url('${response.cover_login}')`;
        setConfiguration(JSON.stringify(response));
      }
    },
    siteLogo() {
      const menuAsideLeftSkin = this.layoutConfig("brand.self.theme");
      // set brand logo
      const logoObject = this.layoutConfig("self.logo");

      let logo;
      if (typeof logoObject === "string") {
        logo = logoObject;
      }
      if (typeof logoObject === "object") {
        logo = objectPath.get(logoObject, menuAsideLeftSkin + "");
      }
      if (typeof logo === "undefined") {
        const logos = this.layoutConfig("self.logo");
        logo = logos[Object.keys(logos)[0]];
      }
      return process.env.BASE_URL + logo;
    },
  },
  computed: {
    ...mapGetters(["layoutConfig"]),

    allowMinimize() {
      return !!this.layoutConfig("aside.self.minimize.toggle");
    },
  },
};
</script>
